import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from 'src/components/Layout'
import SEO from 'src/components/Seo'
import { StoryListQuery } from 'types/graphql-types'

const StoryList: React.FC<PageProps<StoryListQuery>> = ({ data }) => {
  const { t } = useTranslation()
  return (
    <>
      <SEO title={t('story')} />
      <Layout>
        <ul className="md:min-h-screen flex flex-col justify-center items-center py-16">
          {data.stories.nodes.map(story => (
            <li key={story.subTitle} className="text-center pb-16">
              <Link to={`/stories/${story.index}`}>
                <div className="text-xl md:text-2xl tracking-widest">
                  {story.title}
                </div>
                {story.subTitle && (
                  <div className="text-sm tracking-wider">{story.subTitle}</div>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </Layout>
    </>
  )
}

export default StoryList

export const query = graphql`
  query StoryList($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    stories: allStoryJson(filter: { language: { eq: $language } }) {
      nodes {
        title
        subTitle
        index
      }
    }
  }
`
